import React, { useEffect } from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { IFacility } from "interfaces/IMasterdata"
import { Button, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, makeStyles } from "@material-ui/core";
import MasterdataStore from "stores/MasterdataStore";
import { Stores } from "stores";
import { useTranslation } from "react-i18next";
import { TableType } from "interfaces/IMdTable";
import MdTableService from "services/MdTableService";
import AuthenticationStore from "stores/AuthenticationStore";
import { ERoute } from "interfaces/IGlobal";
import RouterStore from "stores/RouterStore";
import RootStore from "stores/RootStore";
import Loader from "./Loader";
import MasterdataDialog from "./MasterdataDialog";
import MUIDataTable from "mui-datatables";

const useStyles = makeStyles((theme) => ({
  table: {
    marginLeft: 30,
    marginRight: 30,
    marginTop: 20,
    minWidth: 650,
  },
  tableEntry: {
    alignContent: "left"
  },
  buttons: {
    marginLeft: 30,
    marginTop: 20,
  },
  button: {
    marginRight: 30,
  },
  logoutButton: {
    marginRight: 30,
  },
}))

interface MdTableContentProps {
  masterdataStore?: MasterdataStore,
  authenticationStore?: AuthenticationStore
  routerStore?: RouterStore
  rootStore?: RootStore
}

const MdTableContent: React.FC<MdTableContentProps> = inject(Stores.masterdataStore)(observer((props) => {
  const masterdataStore = props.masterdataStore!
  const authenticationStore = props.authenticationStore!
  const routerStore = props.routerStore!
  const rootStore = props.rootStore!
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
  
  const classes = useStyles()

  useEffect(() => {
    setIsLoading(false)
    setIsModalOpen(false)
  }, [])
  
  
  const onRowClick = (rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }) => {
    masterdataStore.modalDataIndex = rowMeta.dataIndex
    setIsModalOpen(true)
  }

  const options = {
    searchOpen: false,
    download: false,
    print: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    // selectableRowsOnClick: false,
    rowsPerPage: 15,
    rowsPerPageOptions: [15,25,50,100,1000,5000,10000,100000],
    onRowClick: onRowClick,
  }

  const facilityColumns = [
    {
     name: "name",
     label: t('table:name').toUpperCase(),
     options: {
      filter: false,
      sort: true,
     }
    },
    {
     name: "fId",
     label: t('table:facility-id').toUpperCase(),
     options: {
      filter: false,
      sort: true,
     }
    },
    {
     name: "extRef",
     label: t('table:ext-ref-no').toUpperCase(),
     options: {
      filter: false,
      sort: true,
     }
    },
    {
     name: "orgaIds",
     label: t('table:organization').toUpperCase(),
     options: {
      filter: true,
      sort: true,
     }
    },
    {
     name: "fType",
     label: t('table:type').toUpperCase(),
     options: {
      filter: true,
      sort: true,
     }
    },
    {
      name: "timestamp",
      label: t('table:dataUpdated').toUpperCase(),
      options: {
       filter: false,
       sort: true,
       display: false,
       customBodyRender: (value) => {
         return (<div>{new Date(value).toString()}</div>)
       }
      }
     },
   ]

  const orgaColumns = [
    {
     name: "name",
     label: t('table:name').toUpperCase(),
     options: {
      filter: false,
      sort: true,
     }
    },
    {
     name: "eoId",
     label: t('table:eo-id').toUpperCase(),
     options: {
      filter: false,
      sort: true,
     }
    },
    {
     name: "extRef",
     label: t('table:ext-ref-no').toUpperCase(),
     options: {
      filter: false,
      sort: true,
     }
    },
    {
     name: "orgaId",
     label: t('table:organization').toUpperCase(),
     options: {
      filter: true,
      sort: true,
     }
    },
    {
     name: "address",
     label: t('table:address').toUpperCase(),
     options: {
      filter: false,
      sort: true,
     }
    },
    {
     name: "country",
     label: t('table:country').toUpperCase(),
     options: {
      filter: true,
      sort: true,
     }
    },
    {
     name: "taxRegNumber",
     label: t('table:taxNumber').toUpperCase(),
     options: {
      filter: false,
      sort: true,
     }
    },
    {
     name: "timestamp",
     label: t('table:dataUpdated').toUpperCase(),
     options: {
      filter: false,
      sort: true,
      display: false,
      customBodyRender: (value) => {
        return (<div>{new Date(value).toString()}</div>)
      }
     }
    },
  ]

  const loadingData = () => {
    setIsLoading(true)
    masterdataStore.loadAndSetAll()    
    setIsLoading(false)
  }

	const showButtons = () => {
		return (
			<div className={classes.buttons}>
				<Button
          className={classes.button}
				  variant="contained" 
          color="primary"
          onClick={() => {loadingData()}}
				>
					{t('table:refreshData').toUpperCase()}
				</Button>
				<Button
          className={classes.button}
					variant="contained" 
          color="primary"
          onClick={() => masterdataStore.tableType = TableType.ORGA}
          disabled={masterdataStore.orgaMdEntries.length === 0}
				>
					{t('table:economicOperators').toUpperCase()}
				</Button>
				<Button
          className={classes.button}
				  variant="contained" 
          color="primary"
          onClick={() => masterdataStore.tableType = TableType.FACILITY}
          disabled={masterdataStore.facilityMdEntries.length === 0}
				>
					{t('table:facilities').toUpperCase()}
				</Button>
			</div>
		  )
		}

	const showTable = () => {
    switch (masterdataStore.tableType) {
      case TableType.FACILITY: return facilityTable()
      case TableType.ORGA: return orgaTable()
      default: return (<div className={classes.table}>{t('table:pleaseClickRefresh')}</div>)
    }
  }
  
  const facilityTable = () => {
    return (
      <MUIDataTable
        className={classes.table}
        title={t('table:facilities')}
        data={masterdataStore.facilityMdEntries}
        columns={facilityColumns}
        options={options}
      />
    )
  }
  
  const orgaTable = () => {
    return (
      <MUIDataTable
        className={classes.table}
        title={t('table:economicOperators')}
        data={masterdataStore.orgaMdEntries}
        columns={orgaColumns}
        options={options}
      />
    )
  }

	return (
		<React.Fragment>
			{showButtons()}
			{showTable()}
      {/* <MasterdataDialog   Maybe no modal needed
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        tableType={masterdataStore.tableType}
      /> */}
      <Loader open={isLoading} />
		</React.Fragment>
	);

}))

export default MdTableContent;