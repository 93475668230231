import RootStore from './RootStore'

export enum Stores {
  rootStore = 'rootStore',
  routerStore = 'routerStore',
  authenticationStore = 'authenticationStore',
  masterdataStore = 'masterdataStore',
}

const rootStore = new RootStore()

const stores = {
  [Stores.rootStore]: rootStore,
  [Stores.routerStore]: rootStore.routerStore,
  [Stores.authenticationStore]: rootStore.authenticationStore,
  [Stores.masterdataStore]: rootStore.masterdataStore
}

export default stores
