import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { inject, observer } from 'mobx-react'
import { Stores } from '../stores'
import AuthenticationStore from '../stores/AuthenticationStore'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import styles from './Login.module.scss'
import { useHistory } from "react-router-dom"
import RouterStore, { history } from 'stores/RouterStore'
import { ERoute } from 'interfaces/IGlobal'


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${require('../assets/osapiens_homepage_bgr.png')})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#1274b7",
    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
  },
}))

interface LoginProps {
  authenticationStore?: AuthenticationStore
  routerStore?: RouterStore
  // history: any
}

const Login: React.FunctionComponent<any> = inject(Stores.authenticationStore, Stores.routerStore)(
  observer((props: LoginProps) => {
    const routerStore = props.routerStore
    const history = useHistory()
    const { t } = useTranslation()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const classes = useStyles()

    useEffect(() => {
      props.authenticationStore!
        .JWTLogin()
        .then(
          (response: any) => {
            console.log("RES JWT: ", response);
            // routerStore.navigateTo(ERoute.MDTABLE)
            response && history.push(props.authenticationStore!.path)
          }
        )
    }, [])

    function handleLogin() {
      if (isInputFilled())
        login().catch((error: Error) => alert(t(error.message)))
      else alert(t('login:login-missing'))
    }

    async function login() {
      const response = await props.authenticationStore!.login(email, password)
        console.log("RES login: ", response);
        if (response) history.push(props.authenticationStore!.path)
        // routerStore.navigateTo(ERoute.MDTABLE)
        // if (props.authenticationStore?.loggedIn) alert(t('login:login-success'))
    }

    function isInputFilled() {
      return email !== '' && password !== ''
    }

    return (
      <Grid
        className={`${styles.grid} ${classes.root}`}
        container
        component="main">
        <CssBaseline />
        <Grid item xs={false} sm={4} md={8} className={classes.image} />
        <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t('login:title').toUpperCase()}
            </Typography>
            {/* <form className={classes.form} noValidate> */}
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label={t('login:username')}
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e: any) => setEmail(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label={t('login:password')}
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e: any) => setPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleLogin}>
                {t('login:login').toUpperCase()}
              </Button>
            {/* </form> */}
          </div>
        </Grid>
      </Grid>
    )

  })
)

export default Login
