import { observable, action } from "mobx"
import { TableType } from "interfaces/IMdTable"
import { IFacility, IOrganization, getFacility, getOrganization, registeredOrgas } from "interfaces/IMasterdata"
import RootStore from "./RootStore"
import MdTableService from "services/MdTableService"

export default class MasterdataStore {
  rootStore: RootStore

  mdTableService = new MdTableService()

  @observable tableType: TableType = TableType.NONE
  @observable facilityMdEntries: IFacility[] = []
  @observable selectedFacility: IFacility = getFacility()
  @observable orgaMdEntries: IOrganization[] = []
  @observable selectedOrga: IOrganization = getOrganization()
  @observable modalDataIndex: number = 0

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }


  private orgaIdKeysMapToOrgaNames = (keys: object): string => {
    let s: string = ''
    let ct: number = 0

    if (keys !== null) {
      let vals: any[] = Object.values(keys)

      if (vals.length > 0) {
        vals.forEach((orgaKey: string) => {
          ct++
          const orgaName: string = registeredOrgas[orgaKey] ? registeredOrgas[orgaKey] : orgaKey
          if (ct > 1) s = s + ', '
          s = s + orgaName
        });
      }
    }

    return s
  }

  private orgaIdKeyToOrgaName = (key: string): string => {
    return registeredOrgas[key] ? registeredOrgas[key] : key
  }

  private loadAndSetFacilities = () => {
    this.mdTableService.getAllFacilities()
    .then((data: any[]) => {
      // console.log("loadAndSetFacilities DATA: ", data);
      const facilities: IFacility[] = []
      data.forEach(elem => {
        let facility: IFacility = getFacility()
        if (elem["_id"]) facility._id = elem["_id"]
        if (elem["key"]) facility.key = elem["key"]
        if (elem["eoIdRef"]) facility.eoIdRef = elem["eoIdRef"]
        if (elem["euTerritory"]) facility.euTerritory = elem["euTerritory"]
        if (elem["extRef"]) facility.extRef = elem["extRef"]
        if (elem["fId"]) facility.fId = elem["fId"]
        if (elem["fType"]) facility.fType = elem["fType"]
        if (elem["name"]) facility.name = elem["name"]
        if (elem["status"]) facility.status = elem["status"]
        if (elem["orgaIds"]) facility.orgaIds = this.orgaIdKeysMapToOrgaNames(elem["orgaIds"])
        if (elem["timestamp"]) facility.timestamp = elem["timestamp"]
        facilities.push(facility)
      })

      this.facilityMdEntries = [...facilities]
    })
  }

  private loadAndSetOrganizations = () => {
    this.mdTableService.getAllOrganizations()
    .then((data: any[]) => {
      // console.log("loadAndSetOrganizations DATA: ", data);
      const orgas: IOrganization[] = []
      data.forEach(elem => {
        let orga: IOrganization = getOrganization()
        if (elem["_id"]) orga._id = elem["_id"]
        if (elem["key"]) orga.key = elem["key"]
        if (elem["companyName"]) orga.companyName = elem["companyName"]
        if (elem["country"]) orga.country = elem["country"]
        if (elem["email"]) orga.email = elem["email"]
        if (elem["eoId"]) orga.eoId = elem["eoId"]
        if (elem["euTerritory"]) orga.euTerritory = elem["euTerritory"]
        if (elem["extRef"]) orga.extRef = elem["extRef"]
        if (elem["name"]) orga.name = elem["name"]
        if (elem["status"]) orga.status = elem["status"]
        if (elem["taxRegNumber"]) orga.taxRegNumber = elem["taxRegNumber"]
        if (elem["taxRegVat"]) orga.taxRegVat = elem["taxRegVat"]
        if (elem["orgaIds"][1]) orga.orgaId = this.orgaIdKeyToOrgaName(elem["orgaIds"][1])
        if (elem["timestamp"]) orga.timestamp = elem["timestamp"]
        if (elem["houseNumber"]) {
          orga.houseNumber = elem["houseNumber"]
          orga.address = orga.address + elem["houseNumber"]
        }
        if (elem["street"]) {
          orga.street = elem["street"]
          orga.address = orga.address + elem["street"]
        }
        if (elem["city"]) {
          orga.city = elem["city"]
          orga.address = orga.address + ', ' + elem["city"]
        }
        if (elem["postCode"]) {
          orga.postCode = elem["postCode"]
          orga.address = orga.address + elem["postCode"]
        }
        orgas.push(orga)
      })

      this.orgaMdEntries = [...orgas]
    })
  }

  @action
  loadAndSetAll() {
    this.tableType = TableType.NONE
    this.facilityMdEntries = []
    this.orgaMdEntries = []

    // TODO: Revert after testing!
    this.loadAndSetOrganizations()
    this.loadAndSetFacilities()

  }

}
