import RootStore from './RootStore'
import { createBrowserHistory } from 'history'
import { action, observable } from 'mobx'
import { ERoute } from 'interfaces/IGlobal'


export const history = createBrowserHistory()

class RouterStore {
  rootStore: RootStore
  @observable leaveModalOpen: boolean = false
  @observable valuesChanged: boolean = false
  navigateAway = false
  initialPath: ERoute | string = ERoute.LOGIN
  handleLeave: (save: boolean) => any = () => {}

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @action
  setLeaveModalOpen(open: boolean) {
    this.leaveModalOpen = open
  }

  @action
  setValuesChanged(valuesChanged: boolean) {
    this.valuesChanged = valuesChanged
  }

  @action
  setHandleLeave(handleLeave: (save: boolean) => any) {
    this.handleLeave = handleLeave
  }

  // @action
  // navigateTo(path: ERoute | string, params?: any) {
  //   console.log("=== NAV TO: " + path);
  //   if (window.location.pathname !== path) {
  //     this.navigateAway = true
  //     if (!this.valuesChanged) history.push({ pathname: path, state: params })
  //     else {
  //       this.initialPath = path
  //       this.leaveModalOpen = true
  //     }
  //   }
  // }
}

export default RouterStore
