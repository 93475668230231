export interface IFacility {
  _id: string
  key: string
  eoIdRef: string
  euTerritory: string
  extRef: string
  fId: string
  fType: string
  name: string
  status: string
  orgaIds: string
  timestamp: number
}

export const Facility: IFacility = {
  _id: '',
  key: '',
  eoIdRef: '',
  euTerritory: '',
  extRef: '',
  fId: '',
  fType: '',
  name: '',
  status: '',
  orgaIds: '',
  timestamp: 0,
}

export const getFacility = (): IFacility => {
  return {
    _id: '',
    key: '',
    eoIdRef: '',
    euTerritory: '',
    extRef: '',
    fId: '',
    fType: '',
    name: '',
    status: '',
    orgaIds: '',
    timestamp: 0,
  }
}

export interface IOrganization {
  _id: string
  key: string
  city: string
  companyName: string
  country: string
  email: string
  eoId: string
  euTerritory: string
  extRef: string
  houseNumber: string
  name: string
  postCode: string
  status: string
  street: string
  taxRegNumber: string
  taxRegVat: string
  address: string
  orgaId: string
  timestamp: number
}

export const Organization: IOrganization = {
  _id: '',
  key: '',
  city: '',
  companyName: '',
  country: '',
  email: '',
  eoId: '',
  euTerritory: '',
  extRef: '',
  houseNumber: '',
  name: '',
  postCode: '',
  status: '',
  street: '',
  taxRegNumber: '',
  taxRegVat: '',
  address: '',
  orgaId: '',
  timestamp: 0,
}

export const getOrganization = (): IOrganization => {
  return {
    _id: '',
    key: '',
    city: '',
    companyName: '',
    country: '',
    email: '',
    eoId: '',
    euTerritory: '',
    extRef: '',
    houseNumber: '',
    name: '',
    postCode: '',
    status: '',
    street: '',
    taxRegNumber: '',
    taxRegVat: '',
    address: '',
    orgaId: '',
    timestamp: 0,
  }
}

export const registeredOrgas = {
  "e6f8bd5a-e44b-4d81-840e-c5b7a38956da": "Bestway Northern Limited",
  "d8fc4597-69da-490c-9b66-8c319a3cab86": "Bestway Wholesale Limited"
}
