import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { Provider, observer } from 'mobx-react'
import stores from './stores'
import './i18n'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/core/styles'
import Login from 'pages/Login'
import MdTableContent from 'components/MdTableContent'
import { history } from 'stores/RouterStore'
import { ERoute } from 'interfaces/IGlobal'

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#536DFE',
      main: '#3F51B5',
      dark: '#1a237e',
      contrastText: '#fff',
    },
    secondary: {
      light: '#FF8A80',
      main: '#FF9800',
      dark: '#7B2C02',
      contrastText: '#000',
    },
  },
})

const App: React.FC = observer(() => {
  return (
    <Provider {...stores}>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <Switch>
            <Route exact path={ERoute.LOGIN}>
              <Login />
            </Route>
            <Route exact path={ERoute.MDTABLE}>
              <MdTableContent />
            </Route>
            <Redirect from="/*" to={ERoute.LOGIN} />
          </Switch>
        </Router>
      </ThemeProvider>
    </Provider>
  )
})

export default App
