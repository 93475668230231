import { commonFunctions } from './ServiceConfig'
import HeaderService from './HeaderService'

const { handleResponse, getEndpoint } = commonFunctions
export default class AuthenticationService {
  public authenticate = (username: string, password: string) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        username,
        password,
      }),
    }

    return fetch(`${getEndpoint()}/authenticate`, requestOptions as any)
      .then(handleResponse)
      .then(
        (response) => {
          AuthenticationService.addUser(response)
          return response
        },
        (error: Error) => {
          let message =
            error.message !== '500'
              ? 'errors:loginIncorrectCredentials'
              : 'errors:server'
          throw new Error(message)
        }
      )
  }

  public authenticateWithToken = (jwt: string) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `jwt:${jwt}`,
      },
    }

    return fetch(`${getEndpoint()}/validatejwt`, requestOptions as any)
      .then(handleResponse)
      .then(
        (response) => {
          AuthenticationService.addUser(response)
          return response
        },
        (error: Error) => {
          let message = 'errors:loginFailure\n'
          message =
            error.message !== '500'
              ? message.concat('errors:authenticateJWTFailure')
              : message.concat('errors:server')
          throw new Error(message)
        }
      )
  }

  private static addUser(response: any) {
    localStorage.setItem('jwt', response.jwt)
    localStorage.setItem('user', JSON.stringify(response.user))
    HeaderService.headers['Authorization'] = `jwt:${response.jwt}`
    HeaderService.headers['userId'] = response.user._id

    console.log("HeaderService after addUser: ", HeaderService.headers);
    

    return response
  }

  public removeUser() {
    localStorage.removeItem('jwt')
    localStorage.removeItem('user')
    HeaderService.headers['Authorization'] = ''
    HeaderService.headers['userId'] = ''

    console.log("HeaderService after removeUser: ", HeaderService.headers);
  }
}
