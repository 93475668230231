import RootStore from './RootStore'
import { action, observable } from 'mobx'
import AuthenticationService from '../services/AutheticationService'
import { getUser, IUser } from '../interfaces/IUser'
import { ERoute } from 'interfaces/IGlobal'

export default class AuthenticationStore {
  rootStore: RootStore
  authenticationService = new AuthenticationService()

  @observable loggedIn = false
  @observable logInWithJwt = true
  @observable currentUser: IUser = getUser()
  public path: string = ERoute.MDTABLE

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @action
  setCurrentUser(user: IUser) {
    this.currentUser = { ...user }
  }

  @action
  setUserProperties(authResponse: any) {
    this.loggedIn = true
    const tempUser = { ...this.currentUser }
    Object.assign(tempUser, authResponse.user)
    this.currentUser = tempUser
  }

  @action
  async JWTLogin() {
    const jwt = window.localStorage.getItem('jwt')
    if (jwt) {
      this.rootStore.setLoginLoading(true)
      try {
        const auth = await this.authenticationService.authenticateWithToken(jwt)
        this.setUserProperties(auth)
        this.logInWithJwt = false
        this.rootStore.setLoginLoading(false)
        return auth
      } catch (e) {
        this.logInWithJwt = false
        this.rootStore.setLoginLoading(false)
        // return e
        return null
      }
    } else this.logInWithJwt = false
    this.rootStore.setLoginLoading(false)
    return null
  }

  @action
  async login(email: string, password: string) {
    this.rootStore.setLoginLoading(true)
    try {
      const response = await this.authenticationService.authenticate(
        email,
        password
      )
      this.setUserProperties(response)
      this.rootStore.setLoginLoading(false)
      return response
    } catch (e) {
      this.rootStore.setLoginLoading(false)
      throw e
    }
  }

  @action
  isUserLoggedAndCached() {
    const user = JSON.parse(localStorage.getItem('user') as any)
    const jwt = localStorage.getItem('jwt') as any
    return !!user && !!jwt && this.loggedIn
  }

  @action
  cleanLoginData() {
    this.authenticationService.removeUser()
    this.currentUser = getUser()
    this.logInWithJwt = true
    this.loggedIn = false
  }
}
