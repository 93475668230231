import React from 'react'
import { toast, ToastContainer } from 'react-toastify'
import CustomSnackbar from './CustomSnackbar'

const toastDuration = 5000
export default class Notification extends React.Component {
  render() {
    return (
      <div>
        <ToastContainer
          hideProgressBar={true}
          closeOnClick={false}
          closeButton={false}
          newestOnTop={true}
          autoClose={toastDuration}
          toastClassName="toast-notification-wrap"
        />
      </div>
    )
  }
}

export function notify(message: string, type: 'success' | 'error') {
  // if the message/title is not a string but an object, we
  // transform it into an array of strings, which lists "key: value" of the object
  // exclude keys nobody want's to see

  toast(
    <CustomSnackbar message={message} type={type} duration={toastDuration} />
  )
}
