export interface IUser {
  _id: string
  username: string
}

export const User: IUser = {
  _id: '',
  username: '',
}

export const getUser = (): IUser => {
  return {
    _id: '',
    username: '',
  }
}
