import AuthenticationStore from './AuthenticationStore'
import { action, observable } from 'mobx'
import { createBrowserHistory } from 'history'
import MasterdataStore from './MasterdataStore'
import RouterStore from './RouterStore'

export const history = createBrowserHistory()
export default class RootStore {
  routerStore: RouterStore
  authenticationStore: AuthenticationStore
  masterdataStore: MasterdataStore
  loadingRegistry: {
    [key: string]: boolean
  } = {}
  
  @observable anyLoading = false
  @observable loginLoading = false
  @observable masterdataLoading = false

  constructor() {
    this.routerStore = new RouterStore(this)
    this.authenticationStore = new AuthenticationStore(this)
    this.masterdataStore = new MasterdataStore(this)
  }

  @action
  setLoginLoading(loading: boolean) {
    this.loginLoading = loading
  }

  @action
  setMasterdataLoading(loading: boolean) {
    this.loadingRegistry['masterdata'] = loading
    this.anyLoading = Object.values(this.loadingRegistry).some((l) => l)
    this.masterdataLoading = loading
  }

}
